import React, { useState } from "react"
import { AxiosError, AxiosResponse } from "axios"

// import axios from "../../../ api/iskibris/iskibris"
// import axios from "axios"

import iskibrisapi from "../../../api/iskibris/client"
import { TextField, IconButton, InputAdornment, Link, CircularProgress, Grid, Button } from "@mui/material"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Visibility from "@mui/icons-material/Visibility"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"

import useStyles from "../styles"
import AuthError from "../AuthError"
import Greetings from "../Greetings"
import GoogleOauth from "../GoogleAuth"
import FacebookAuth from "../FacebookAuth"
import CustomDivider from "../CustomDivider"

interface Props {
  onSuccess: () => void
  onFacebookLogin: () => void
  onGoogleLogin: () => void
}

const Signin: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    email: "",
    password: ""
  })

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [wrongCredentials, setWrongCredentials] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const setValuesHandler = (name: any) => (event: any) => setValues({ ...values, [name]: event.target.value })

  const serverLoginHandler = async (event: any) => {
    event?.preventDefault()

    setLoading(true)
    setWrongCredentials(false)

    const data = {
      username: values.email,
      password: values.password,
      grant_type: process.env.NEXT_PUBLIC_GRANT_TYPE,
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET
    }

    iskibrisapi
      .post("/oauth/token", data)
      .then(async (response: AxiosResponse) => {
        if (response.status === 200) {
          const accessToken = response.data["access_token"]

          await Cookies.set("auth_token", accessToken, {
            domain: process.env.NEXT_PUBLIC_APP_DOMAIN || ".iskibris.com",
            sameSite: "none",
            secure: true,
            expires: 364
          })

          Cookies.set("is_employer", "true", {
            domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
            sameSite: "None",
            secure: true,
            expires: 750
          })

          await localStorage.setItem("auth_token", accessToken)
          await localStorage.setItem("refresh_token", response.data["refresh_token"])
          setLoading(false)
          props.onSuccess()
        }
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          setWrongCredentials(true)
          setLoading(false)
        }
      })
  }

  let loginFailureMessage = null

  if (wrongCredentials) {
    loginFailureMessage = <AuthError></AuthError>
  }

  return (
    <div className={classes.root}>
      <Greetings />
      <div>
        <FacebookAuth mode="login" onSuccess={props.onFacebookLogin} onFail={() => {}} />
        <GoogleOauth
          mode="login"
          onSuccess={props.onGoogleLogin}
          onAuthenticated={() => {
            props.onGoogleLogin
          }}
          onFail={() => {}}
        />
      </div>
      <CustomDivider />
      {loginFailureMessage}
      <div>
        <form onSubmit={serverLoginHandler}>
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            size="small"
            id="email"
            label="E-posta"
            name="email"
            autoComplete="email"
            onChange={setValuesHandler("email")}
          />
          <TextField
            data-private="lipsum"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            size="small"
            name="password"
            label={t("password")}
            id="password"
            autoComplete="current-password"
            onChange={setValuesHandler("password")}
            value={values.password}
            type={showPassword ? "text" : "password"}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#e61e8f",
              marginTop: "4%",
              "&:hover": {
                color: "white",
                background: "#e61e8f"
              }
            }}
            key={Math.random()}
            className={classes.submit}
            style={{ marginTop: "15px", textTransform: "none" }}
          >
            {loading ? <CircularProgress size="30px" style={{ color: "white" }} /> : "Giriş"}
          </Button>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.extraActionButtonGrid}>
              <Button variant="text" style={{ textTransform: "none" }} href="/forgot-password">
                Şifremi unuttum?
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  )
}

export default Signin
